import * as React from 'react';
import PropTypes from 'prop-types';
import InputText from '../forms/input-text';
import InputPhone from '../forms/input-phone';
import InputCheckbox from '../forms/input-checkbox';
import BtnSubmitRed from '../buttons/btn-submit-red';
import { useForm, FormProvider } from 'react-hook-form';
import axios from 'axios';
import InputSelect from '../forms/input-select';
import InputPostal from '../forms/input-postal';
import BtnNextRed from '../buttons/btn-next-red';
import {navigate} from "gatsby";
import {useSiteMetadata} from "../../hooks/use-site-metadata";

const HeaderForm = (props) => {
	const [step, setStep] = React.useState(0);
	const test = props.label.buildingInProgress;
	const onSubmit = async (formData) => {
		let questions = {};
		let buildingInProgress = formData.buildingInProgress;
		questions[test] = formData.buildingInProgress;

		delete formData.buildingInProgress;

		formData.questions = questions;

		try {

			await axios
				.post(process.env.API_URL + "/leads", formData)
				.then((response) => {
					let queryParams = {};
					queryParams["hash"] = response.data.hash;
					queryParams["question"] = buildingInProgress;
					navigate("/podziekowanie?"+(new URLSearchParams(queryParams).toString()));
				});
				// .catch()
				// .finally(() => console.log('..REQEST FINISHED.'));
		}catch (err) {
			console.log('ss');
		}

	};
	const methods = useForm();

	return (
		<div className='form__contact md:shrink-0 w-full md:w-[430px]'>
			<header className='mb-4'>
				<h3 className='title__h3 mb-2 md:mb-2.5'>{props.title}</h3>
				<p className='text__lead'>{props.caption}</p>
			</header>
			{step === 0 && (
				<>
					<FormProvider {...methods}>
						<form
							action={props.action}
							method={props.method}
							onSubmit={methods.handleSubmit(() => setStep(1))}
						>
							<InputSelect
								id='buildingInProgress'
								name='buildingInProgress'
								label={props.label.buildingInProgress}
								required={true}
								options={["W trakcie budowy", "Został ukończony"]}
							></InputSelect>
							<InputPostal
								id='postal'
								name='postal_code'
								label={props.label.postal}
								required={true}
							></InputPostal>
							<div className='text-center sm:text-left'>
								<BtnNextRed label='Dalej'></BtnNextRed>
							</div>
						</form>
					</FormProvider>
				</>
			)}

			{step === 1 && (
				<>
					<FormProvider {...methods}>
						<form
							action={props.action}
							method={props.method}
							onSubmit={methods.handleSubmit(onSubmit)}
						>
							<InputText
								id='name'
								name='name'
								label={props.label.name}
								required={true}
							></InputText>
							<InputText
								id='email'
								name='email'
								label={props.label.email}
								required={true}
							></InputText>
							<InputPhone
								id='phone'
								name='phone'
								label={props.label.phone}
								required={true}
							></InputPhone>
							<InputCheckbox
								id='term'
								name='term'
								label={props.label.term}
								required={true}
							></InputCheckbox>
							<div className='text-center sm:text-left'>
								<BtnSubmitRed label={props.submit}></BtnSubmitRed>
							</div>
						</form>
					</FormProvider>
				</>
			)}
		</div>
	);
};

export default HeaderForm;

HeaderForm.propTypes = {
	method: PropTypes.oneOf(['GET', 'POST']),
	action: PropTypes.string,
	title: PropTypes.string,
	caption: PropTypes.string,
	label: PropTypes.object,
	submit: PropTypes.string,
};

HeaderForm.defaultTypes = {
	method: 'GET',
	action: '',
};
