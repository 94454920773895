import * as React from "react";
import PropTypes from "prop-types";

const firstItem = (id, parentId, item) => (
    <div key={id} className="accordion-item">
        <h2 className="accordion-header" id={id}>
            <button className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#col${id}`}
                    aria-expanded="false" aria-controls={`col${id}`}>{item.title}</button>
        </h2>
        <div id={`col${id}`}
             className="accordion-collapse collapse show"
             aria-labelledby={id}
             data-bs-parent={`#${parentId}`}>
            <div className="accordion-body">{item.content}</div>
        </div>
    </div>
);

const accordionItem = (id, parentId, item) => (
    <div key={id} className="accordion-item">
        <h2 className="accordion-header" id={id}>
            <button className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#col${id}`}
                    aria-expanded="false" aria-controls={`col${id}`}>{item.title}</button>
        </h2>
        <div id={`col${id}`}
             className="accordion-collapse collapse"
             aria-labelledby={id}
             data-bs-parent={`#${parentId}`}>
            <div className="accordion-body">{item.content}</div>
        </div>
    </div>
);

const FaqAccordion = (props) => {
    const items = props.items.map(((item, key) => key === 0 ? firstItem(`fa${key}`, props.id, item) : accordionItem(`fa${key}`, props.id, item)));

    return (
        <div className="accordion accordion-flush" id={props.id}>{items}</div>
    );
}

export default FaqAccordion;

FaqAccordion.propTypes = {
    id: PropTypes.string,
    items: PropTypes.array,
}

FaqAccordion.defaultProps = {
    id: 'accordionFlushExample',
    items: []
}
