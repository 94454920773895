import React from 'react';

const Content = ({ title, paragraphs }) => {
	return (
		<section className='pt-16 pb-12 sm:pb-48 md:pb-40 bg-white'>
			<div className='container text-center'>
				<header className='mb-6 suffix__bottom-blue--center'>
					<p className='title__prefix mb-5'>{title}</p>
					<h2 className='title__h2 pb-5'>{title}</h2>
				</header>
				{paragraphs.map((paragraph) => (
					<p
						className='text__content text-left max-w-4xl mx-auto'
						key={paragraph}
					>
						{paragraph}
					</p>
				))}
			</div>
		</section>
	);
};

export default Content;
