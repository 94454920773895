import * as React from "react";
import PropTypes from "prop-types";
import BtnPhoneBlue from "../buttons/btn-phone-blue";
import {useSiteMetadata} from "../../hooks/use-site-metadata";
import FaqAccordion from "./accordion";

const FaqSectionWrapper = (props) => {
    const {phone} = useSiteMetadata();

    return (
        <section className="container py-20 flex flex-wrap md:flex-nowrap justify-between items-center">
            <div className="w-full md:max-w-[470px] md:grow md:pr-8">
                <header className="mb-7 suffix__bottom-blue">
                    <p className="title__prefix mb-5">{props.titlePrefix}</p>
                    <h2 className="title__h2 pb-5 max-w-[370px]">{props.title}</h2>
                </header>
                <p className="mt-5 text__content">{props.content}</p>
                <p className="mt-9">
                    <BtnPhoneBlue label={props.buttonText} phoneNumber={phone}></BtnPhoneBlue>
                </p>
            </div>
            <div className="md:shrink-0 mt-8 md:mt-0 w-full md:w-[420px] lg:w-[530px]">
                <FaqAccordion id="faqAccordion" items={props.accordion}></FaqAccordion>
            </div>
        </section>
    )
}

export default FaqSectionWrapper

FaqSectionWrapper.propTypes = {
    title: PropTypes.string,
    titlePrefix: PropTypes.string,
    content: PropTypes.string,
    buttonText: PropTypes.string,
    accordion: PropTypes.array
}
