import * as React from "react";
import PropTypes from "prop-types";
import Image from "../image";
import {numberRandomize} from "../../utils/number-randomize";

const AboutSectionWrapper = (props) => {
    const listGridItems = props.grid.map(item => {
        const hash = numberRandomize(100000, 10000000);
        return (
            <li key={hash}>
                <Image src={item.icon} alt={props.title} className="mb-7 mx-auto w-[57px] h-[57px]"/>
                <p className="title__h4 mb-3">{item.title}</p>
                <p className="text__content">{item.content}</p>
            </li>
        );
    });

    return (
        <section className="pt-16 pb-12 sm:pb-48 md:pb-40 bg-white">
            <div className="container text-center">
                <header className="mb-6 suffix__bottom-blue--center">
                    <p className="title__prefix mb-5">{props.titlePrefix}</p>
                    <h2 className="title__h2 pb-5">{props.title}</h2>
                </header>
                <p className="text__content max-w-4xl mx-auto">{props.content}</p>
                <ul className="list__grid--3-column mt-12">{listGridItems}</ul>
            </div>
        </section>
    )
}

export default AboutSectionWrapper

AboutSectionWrapper.propTypes = {
    titlePrefix: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    grid: PropTypes.array
}