import * as React from "react";
import PropTypes from "prop-types";
import Image from "../image";
import {numberRandomize} from "../../utils/number-randomize";

const Photo1SectionWrapper = (props) => {
    const listItems = props.list.map(item => {
        const hash = numberRandomize(100000, 10000000);
        return (
            <li key={hash} className="mb-5">
                <Image src={item.icon} alt={item.title} />
                <span className="text-lg font-bold mb-3 pt-0.5 block">{item.title}</span>
                <p className="text__content">{item.content}</p>
            </li>
        );
    });

    return (
        <div className="sm:mt-32 md:mt-28">
            <section className="container pb-12 pt-24 md:py-20 md:flex flex-wrap md:flex-nowrap justify-between items-center">
                <div className="mb-8 md:mb-0 md:max-w-[530px] md:grow md:pr-8">
                    <header className="mb-7 suffix__bottom-blue">
                        <p className="title__prefix mb-5">{props.titlePrefix}</p>
                        <h2 className="title__h2 pb-5 max-w-[430px]">{props.title}</h2>
                    </header>
                    <ul className="list__icon">{listItems}</ul>
                    <p className="mt-5 text__content">{props.content}</p>
                </div>
                <div className="md:shrink-0">
                    <Image src={props.photo} alt={props.title} className="max-w-full max-h-auto md:pt-0 md:max-w-[400px] md:max-h-[493px] lg:max-w-[520px] lg:max-h-[640px]"/>
                </div>
            </section>
        </div>
    )
}

export default Photo1SectionWrapper

Photo1SectionWrapper.propTypes = {
    title: PropTypes.string,
    titlePrefix: PropTypes.string,
    content: PropTypes.string,
    photo: PropTypes.any,
    list: PropTypes.array
}