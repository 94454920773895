import * as React from "react";
import PropTypes from "prop-types";
import { Swiper, SwiperSlide } from 'swiper/react';
import {numberRandomize} from "../../utils/number-randomize";

let swiperRef;

function nextClickHandler() {
    setTimeout(() => swiperRef.slideNext(), 1);
}
function prevClickHandler() {
    setTimeout(() => swiperRef.slidePrev(), 1);
}

const getSwiperItems = (item) => {
    const hash = numberRandomize(100000, 1001000);
    return (
        <SwiperSlide key={hash}>
            <div className="bg-blue p-7 rounded-lg">
                <h5 className="font-extrabold text-lg text-white mb-4">{item.title}</h5>
                <div className="text__content--lightgrey" dangerouslySetInnerHTML={{ __html: item.content }}></div>
                <div className="mt-4 flex items-center">
                    <div className="bg-white rounded-full w-[60px] h-[60px] mr-4"></div>
                    <div>
                        <p className="font-semibold text-white">{item.author}</p>
                        <p className="text-white text-xs">{item.authorRole}</p>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    );
}

const OpinionCarousel = (props) => {
    const swiperItems = props.items.map(item => getSwiperItems(item));
    return (
        <div className="relative">
            <div className="opinion__swiper-navigation">
                <button aria-label="Poprzednie" type="button" className="opinion__swiper-navigation--prev" title="Poprzednie" onClick={prevClickHandler}></button>
                <button aria-label="Następne" type="button" className="opinion__swiper-navigation--next" title="Następne" onClick={nextClickHandler}></button>
            </div>
            <Swiper
                className="cursor-ew-resize"
                spaceBetween={15}
                loop={true}
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    768: {
                        slidesPerView: 2
                    }
                }}
                onSwiper={(swiper) => (swiperRef = swiper)}>{swiperItems}</Swiper>
        </div>
    )
}

export default OpinionCarousel;

OpinionCarousel.propTypes = {
    items: PropTypes.array
}

OpinionCarousel.defaultProps = {
    items: []
}
