import * as React from 'react';
import HeaderMenu from './menu';
import HeaderStaticBox from './static-box';
import HeaderForm from './form';
import PropTypes from 'prop-types';
import { useSiteMetadata } from '../../hooks/use-site-metadata';

const HeaderWrapper = (props) => {
	const { phone, email, title } = useSiteMetadata();

	return (
		<section className='header-wrapper'>
			<HeaderMenu
				info={props.header.info}
				phone={phone}
				email={email}
				title={title}
			></HeaderMenu>
			<div className='pt-6 pb-14 container md:flex flex-row md:items-center md:justify-between'>
				<HeaderStaticBox
					title={props.header.title}
					titlePrefix={props.header.titlePrefix}
					content={props.header.content}
					contentLi={props.header.contentLi}
				></HeaderStaticBox>
				<HeaderForm
					title={props.form.title}
					caption={props.form.caption}
					label={props.form.label}
					submit={props.form.submit}
				></HeaderForm>
			</div>
		</section>
	);
};

export default HeaderWrapper;

HeaderWrapper.propTypes = {
	header: PropTypes.object,
	form: PropTypes.object,
};
