import * as React from "react";
import PropTypes from "prop-types";
import OpinionCarousel from "./opinion-carousel";
import Image from "../image";

const ClientSectionWrapper = (props) => {
    return (
        <div className="bg-darkblue">
            <section className="container py-12 md:py-20">
                <header className="mb-12 suffix__bottom-blue md:pr-[130px]">
                    <p className="mb-5">
                        <Image src="icons/quote.svg" alt={props.title} className="h-[33px] md:h-auto"></Image>
                    </p>
                    <h2 className="title__h2--white mb-5">{props.title}</h2>
                    <p className="text__content--lightgrey pb-5">{props.content}</p>
                </header>
                <div className="mt-[130px] md:mt-0">
                    <OpinionCarousel items={props.opinions}></OpinionCarousel>
                </div>
            </section>
        </div>
    )
}

export default ClientSectionWrapper

ClientSectionWrapper.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    opinions: PropTypes.array,
}