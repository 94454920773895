import * as React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import InputMask from 'react-input-mask';

const InputPostal = (props) => {
	const { register } = useFormContext();
	const { id, name, required, label } = props;

	return (
		<div className='mb-4'>
			<label htmlFor={id} className='form__label'>
				{label}
			</label>
			<InputMask
				mask='99-999'
				id={id}
				className='form__input'
				inputMode='numeric'
				type='text'
				{...register(name, {
					required: required,
					pattern: {
						value: /^[0-9]{2}(-[0-9]{3})?$/,
						message: 'Wprowadź poprawny kod pocztowy',
					},
				})}
			/>
		</div>
	);
};

export default InputPostal;

InputPostal.propTypes = {
	id: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	required: PropTypes.bool,
};
