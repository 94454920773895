import * as React from "react";
import PropTypes from "prop-types";
import Image from "../image";
import {numberRandomize} from "../../utils/number-randomize";

const HowHelpSectionWrapper = (props) => {
    const itemsGrid = props.grid.map(item => {
        const hash = `hhs${numberRandomize(1000, 10000000)}`;
        return (
            <div key={hash} className="md:w-[260px] mb-10 md:mb-0">
                <Image src={item.icon} alt={item.title} className="w-[57px] h-[57px] mx-auto mb-7"></Image>
                <h5 className="title__h4" dangerouslySetInnerHTML={{ __html: item.title }}></h5>
                <div className="mt-5" dangerouslySetInnerHTML={{ __html: item.content }}></div>
            </div>
        );
    });
    return (
        <div className="bg-white">
            <section className="container pt-12 pb-4 md:py-20 text-center">
                <div className="max-w-[800px] mx-auto">
                    <header className="mb-7 suffix__bottom-blue--center">
                        <p className="title__prefix mb-5">{props.titlePrefix}</p>
                        <h2 className="title__h2 pb-5">{props.title}</h2>
                    </header>
                    <p className="mt-5 text__content">{props.content}</p>
                </div>
                <div className="flex flex-col md:flex-row items-center md:items-start md:justify-between items-start mt-9 md:mb-8 md:mx-[60px] lg:mx-[80px] xl:mx-[100px]">{itemsGrid}</div>
                <Image src="icons/sciezka-kontaktu.svg" alt="Ścieżka kontaktu" className="hidden md:block"></Image>
            </section>
        </div>
    );
}

export default HowHelpSectionWrapper;

HowHelpSectionWrapper.propTypes = {
    titlePrefix: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    grid: PropTypes.array
}

HowHelpSectionWrapper.defaultProps = {
    grid: []
}