import * as React from "react";
import PropTypes from "prop-types";

const BtnSubmitRed = (props) => {
    return (
        <button type="submit" className="btn__submit--red text-center sm:text-left w-full sm:w-auto">
            <span>{props.label}</span>
        </button>
    )
}

export default BtnSubmitRed

BtnSubmitRed.prototype = {
    label: PropTypes.string
}