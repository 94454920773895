import React from "react";
import PropTypes from "prop-types";
import HeaderForm from "../header/form";
import {numberRandomize} from "../../utils/number-randomize";

const ContactSectionWrapper = (props) => {
    const listItems = props.list.map(item => {
        const hash = numberRandomize(1000000, 10000000);
        return (
            <li key={hash}>{item}</li>
        );
    });
    return (
        <section className="bg-white2 py-12 md:py-20 overflow-hidden">
            <div className="container md:my-[50px]">
                <div className="bg__plumbs bg-darkblue2 xl:mx-[-80px] p-8 lg:py-0 xl:px-[80px]">
                    <div className="w-full flex flex-col md:flex-row items-center md:justify-between">
                        <div className="lg:max-w-[550px] pr-0 md:pr-8">
                            <header className="mb-7 suffix__bottom-blue">
                                <h2 className="title__h2--white mb-5">{props.title}</h2>
                                <p className="pb-5 text__content--lightgrey">{props.content}</p>
                            </header>
                            <ul className="list__check--red">{listItems}</ul>
                        </div>
                        <div className="mt-8 md:my-[-50px] mx-[-50px] md:mx-0">
                            <HeaderForm {...props.form}></HeaderForm>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ContactSectionWrapper;

ContactSectionWrapper.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    list: PropTypes.array,
    form: PropTypes.object
}

ContactSectionWrapper.defaultProps = {
    list: []
}