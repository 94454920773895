import * as React from "react";
import PropTypes from "prop-types";

const HeaderStaticBox = (props) => {
    return (
        <div className="w-full md:max-w-[600px] mb-8 md:mb-0 mr-0 md:mr-8">
            <p className="title__prefix">{props.titlePrefix}</p>
            <h1 className="title mb-5" dangerouslySetInnerHTML={{ __html: props.title }}></h1>
            <p className="text__content">{props.content}</p>
            <ul className="mt-7 list__check--green">
                <li>{props.contentLi}</li>
            </ul>
        </div>
    )
}

export default HeaderStaticBox

HeaderStaticBox.propTypes = {
    title: PropTypes.string,
    titlePrefix: PropTypes.string,
    content: PropTypes.string,
    contentLi: PropTypes.string,
}
