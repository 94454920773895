import 'tw-elements';
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import HeaderWrapper from '../components/header/wrapper';
import AboutSectionWrapper from '../components/about-section/wrapper';
import NumbersSectionWrapper from '../components/numbers-section/wrapper';
import Photo1SectionWrapper from '../components/photo1-section/wrapper';
import HowWorkSectionWrapper from '../components/how-work-section/wrapper';
import FaqSectionWrapper from '../components/faq-section/wrapper';
import ClientSectionWrapper from '../components/client-section/wrapper';
import HowHelpSectionWrapper from '../components/how-help-section/wrapper';
import ContactSectionWrapper from '../components/contact-section/wrapper';
import FooterWrapper from '../components/footer/wrapper';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import HeaderMenu from '../components/header/menu';
import Content from '../components/content/content';

const LandingPage = ({ data }) => {
	const page = data.dataJson;
	const { title } = useSiteMetadata();

	return (
		<main>
			<title>{`${page.title} ${title}`}</title>
			{page.form ? (
				<HeaderWrapper header={page.header} form={page.form}></HeaderWrapper>
			) : (
				<HeaderMenu {...page.header} />
			)}
			{page.paragraphs ? (
				<Content title={page.header.title} paragraphs={page.paragraphs} />
			) : (
				<>
					<AboutSectionWrapper {...page.about}></AboutSectionWrapper>
					<NumbersSectionWrapper items={page.numbers}></NumbersSectionWrapper>
					<Photo1SectionWrapper {...page.withPhoto1}></Photo1SectionWrapper>
					<HowWorkSectionWrapper {...page.howWork}></HowWorkSectionWrapper>
					<FaqSectionWrapper {...page.faq}></FaqSectionWrapper>
					<ClientSectionWrapper {...page.clients}></ClientSectionWrapper>
					<HowHelpSectionWrapper {...page.howHelp}></HowHelpSectionWrapper>
					<ContactSectionWrapper
						{...page.contact}
						form={page.form}
					></ContactSectionWrapper>
				</>
			)}
			<FooterWrapper pages={page.footer}></FooterWrapper>
		</main>
	);
};

export default LandingPage;

LandingPage.propTypes = {
	title: PropTypes.string,
	header: PropTypes.object,
	form: PropTypes.object,
	about: PropTypes.object,
	numbers: PropTypes.array,
	withPhoto1: PropTypes.object,
	howWork: PropTypes.object,
	faq: PropTypes.object,
	clients: PropTypes.object,
	howHelp: PropTypes.object,
	contact: PropTypes.object,
	footer: PropTypes.object,
};

export const query = graphql`
	query ($slug: String!) {
		dataJson(slug: { eq: $slug }) {
			title
			header {
				info
				title
				titlePrefix
				content
				contentLi
			}
			paragraphs
			form {
				title
				caption
				label {
					buildingInProgress
					postal
					name
					email
					phone
					term
				}
				submit
			}
			about {
				titlePrefix
				title
				content
				grid {
					content
					title
					icon
				}
			}
			numbers {
				title
				value
			}
			withPhoto1 {
				titlePrefix
				title
				content
				photo
				list {
					title
					icon
					content
				}
			}
			howWork {
				title
				titlePrefix
				content
				photo
				list {
					title
					icon
					content
				}
			}
			faq {
				titlePrefix
				title
				content
				buttonText
				accordion {
					title
					content
				}
			}
			clients {
				title
				content
				opinions {
					title
					content
					author
					authorRole
				}
			}
			howHelp {
				titlePrefix
				title
				content
				grid {
					title
					icon
					content
				}
			}
			contact {
				title
				content
				list
			}
			footer {
				label
				url
			}
		}
	}
`;
