import * as React from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import InputMask from "react-input-mask";

const InputPhone = (props) => {
    const { register } = useFormContext();
    const { name, required } = props;

    return (
        <div className="mb-4">
            <label htmlFor={props.id} className="form__label">{props.label}</label>
            {/*<input id={props.id} type="tel" className="form__input" {...register(props.name, { required: props.required })}/>*/}

            <InputMask
                mask='999-999-999'
                id={props.id}
                className='form__input'
                inputMode='numeric'
                type='tel'
                {...register(name, {
                    required: required,
                    pattern: {
                        value: /^[0-9]{3}-[0-9]{3}-[0-9]{3}$/,
                        message: 'Wprowadź poprawny numer telefonu',
                    },
                })}
            />
        </div>
    )
}

export default InputPhone;

InputPhone.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
}
