import * as React from "react";
import PropTypes from "prop-types";
import {numberRandomize} from "../../utils/number-randomize";

const NumbersSectionWrapper = (props) => {
    const listItems = props.items.map(item => {
        const hash = numberRandomize(10000, 1000000);
        return (
            <li key={hash}>
                <span className="text-red font-extrabold text-[46px]">{item.value}</span>
                <p className="text__content">{item.title}</p>
            </li>
        );
    })
    return (
        <div className="container relative">
            <section className="absolute__center--sm w-full bg-white px-8 py-0 sm:p-8 drop-shadow-3xl">
                <ul className="list__numbers--red">{listItems}</ul>
            </section>
        </div>
    )
}

export default NumbersSectionWrapper

NumbersSectionWrapper.propTypes = {
    items: PropTypes.array
}