import * as React from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";

const InputCheckbox = (props) => {
    const { register } = useFormContext();

    return (
        <div className="form-group__checkbox mb-4">
            <input id={props.id} type="checkbox" className="form__input" {...register(props.name, { required: props.required })}/>
            <label aria-label={props.label} htmlFor={props.id} className="form__label--checkbox" dangerouslySetInnerHTML={{ __html: props.label }}></label>
        </div>
    )
}

export default InputCheckbox;

InputCheckbox.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    required: PropTypes.bool
}
