import * as React from "react";
import PropTypes from "prop-types";

const BtnPhoneBlue = (props) => {
    return (
        <a className="btn__phone--blue text-center xs:text-left w-full xs:w-auto" href={`tel:${props.phoneNumber}`}>
            <span>{props.label}</span>
        </a>
    )
}

export default BtnPhoneBlue

BtnPhoneBlue.prototype = {
    phoneNumber: PropTypes.string,
    label: PropTypes.string
}